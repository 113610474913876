<template>
    <div class="course_box">
        <div class="banner">
            <img class="bannerImg" :src="formData.carouselImgUrl" alt="">
        </div>
        <div class="module1">
            <div class="content">
                <CourseTitle title="发展潜力大，职业应用领域广泛" v-if="formData.applicationField" :contText="formData.applicationField">
                </CourseTitle>
                <div class="swiper_box">
                    <swiper class="swiper" ref="swiper" :options="swiperOption">
                        <swiper-slide v-for="(item, index) in formData.courseApplicationFieldVOS" :key="index">
                            <div class="list display column">
                                <img class="swiper_img" :src="item.applicationFieldImgUrl" alt="">
                                <div class="text">{{ item.applicationFieldName }}</div>
                            </div>
                        </swiper-slide>

                        <div class="swiper-button-prev" slot="button-prev">
                            <img class="icon_left_right" src="@/assets/img/home/left1.png" alt="">
                        </div>
                        <div class="swiper-button-next" slot="button-next">
                            <img class="icon_left_right" src="@/assets/img/home/right1.png" alt="">
                        </div>
                    </swiper>
                </div>
            </div>
        </div>
        <div class="module2">
            <div class="content">
                <CourseTitle title="就业前景好，薪资水平高" :contText="formData.industryProspects">
                </CourseTitle>
                <div class="recruitment display">
                    <div class="list display pointer" :class="recruitmentIndex == index ? 'recruitmentActive' : ''"
                        v-for="(item, index) in formData.courseIndustryProspectsVOS" :key="index" @click="changeBtn(index)">
                        {{ item.platformName
                        }}</div>
                </div>
                <div class="recruitment_img display" v-if="formData.courseIndustryProspectsVOS[recruitmentIndex]">
                    <img :src="formData.courseIndustryProspectsVOS[recruitmentIndex].platformImgUrl" alt="">
                </div>
            </div>
        </div>
        <div class="module3">
            <div class="content">
                <CourseTitle title="就业靠竞争，上岗凭技能" :contText="formData.skill">
                </CourseTitle>
                <el-carousel :interval="5000">
                    <el-carousel-item v-for="(item, index) in formData.courseSkillVOS" :key="index">
                        <div class="recommend_employment flex">
                            <img class="icon_tj" :src="item.skillImgUrl" alt="">
                            <img class="icon_tjImg" src="@/assets/img/course/icon_tjImg.png" alt="" />
                            <div class="cont_box">
                                <div class="jobs_tuitionFees flex">
                                    <div class="jobs">{{ item.skillName }} </div>
                                    <!-- <div class="tuitionFee"> 学费：{{ item.tuitionFee }}元</div> -->
                                </div>
                                <!-- <div class="list flex">
                                    <div class="label display">打包培训</div>
                                    <div class="text">
                                        {{ item.packagingTraining}}
                                    </div>
                                </div> -->
                                <div class="list flex">
                                    <div class="label display">培训时长</div>
                                    <div class="text ">
                                        {{ item.trainingOften }}
                                    </div>
                                </div>
                                <div class="list flex">
                                    <div class="label display">课程简介</div>
                                    <div class="text course_introduction">
                                        {{ item.individualTraining }}</div>
                                </div>
                                <div class="btn display pointer" @click="service">立即查询学费</div>
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>

            </div>
        </div>
        <div class="module4">
            <div class="content module4_content">
                <CourseTitle title="教师技术本领精湛，教学经验丰富" :contText="formData.instructors">
                </CourseTitle>
                <Lecturer :lecturerList="formData.teacherVOS"></Lecturer>
                <!-- <div class="carousel_box">
                    <el-carousel :interval="5000" arrow="always">
                        <el-carousel-item v-for="(item,index) in formData.teacherVOS" :key="index">
                            <div class="el_carousel_cont flex">
                                <div class="el_carousel_cont_fl">
                                    <img :src="item.recommendAvatar" alt="">
                                </div>
                                <div class="el_carousel_cont_fr">
                                    <div class="lecturer_name">{{ item.lecturerName }}
                                        <div class="line"></div>
                                    </div>
                                    <div class="lecturer_details">

                                        {{ item.introduction }}</div>
                                    <div class="learn_more display pointer" @click="service">了解更多</div>
                                </div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div> -->
            </div>
        </div>
        <div class="module5">
            <div class="content">
                <CourseTitle title="全程面授 ，一线施工现场教学" :contText="formData.constructionSite">
                </CourseTitle>
                <div class="swiper_box">
                    <swiper class="swiper" ref="swiper" :options="swiperOptions">
                        <swiper-slide v-for="(item, index) in formData.courseConstructionSiteVOS" :key="index">
                            <div class="list display column">
                                <img class="swiper_img" :src="item.constructionSiteUrl" alt="">
                                <div class="text display">{{ item.constructionSiteName }}</div>
                            </div>
                        </swiper-slide>

                        <div class="swiper-button-prev" slot="button-prev">
                            <img class="icon_left_right" src="@/assets/img/home/left1.png" alt="">
                        </div>
                        <div class="swiper-button-next" slot="button-next">
                            <img class="icon_left_right" src="@/assets/img/home/right1.png" alt="">
                        </div>
                    </swiper>
                </div>
            </div>
        </div>
        <div class="module6">
            <div class="content">
                <CourseTitle title="提供岗前培训、推荐就业等一系列就业服务" contText="">
                </CourseTitle>
                <div class="employment_services flex">
                    <div class="list flex-center column" :class="servicesIndex == index ? 'listActive' : ''"
                        v-for="(item, index) in formData.courseEmploymentServicesVOS" :key="index"
                        @mouseover="mouseOver(index)" @mouseleave="mouseLeave">
                        <img :src="servicesIndex == index ? item.highlightIcon : item.icon" alt="">
                        <div class="title" :class="servicesIndex == index ? 'active' : ''"> {{ item.serviceName }}
                        </div>
                        <div class="cont" :class="servicesIndex == index ? 'active' : ''">{{ item.serviceIntroduce }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module7">
            <div class="content">
                <CourseTitle title="悄悄拔尖，拥抱高薪人生" contText="">
                </CourseTitle>
                <div class="swiper_box">
                    <swiper class="swiper" ref="swiper" :options="swiperOptionss">
                        <swiper-slide v-for="(item, index) in formData.courseHighPayingStudentsVOS" :key="index">
                            <div class="list ">
                                <img class="swiper_img" :src="item.traineesAvatarUrl" alt="">
                                <div class="cont">
                                    <div class="name ">学员-{{ item.traineesName }}</div>
                                    <div class="salary ">{{ item.highPayingStudents }}</div>

                                </div>
                            </div>
                        </swiper-slide>

                        <div class="swiper-button-prev" slot="button-prev">
                            <img class="icon_left_right" src="@/assets/img/home/left1.png" alt="">
                        </div>
                        <div class="swiper-button-next" slot="button-next">
                            <img class="icon_left_right" src="@/assets/img/home/right1.png" alt="">
                        </div>
                    </swiper>
                </div>
            </div>
        </div>
        <div class="module8">
            <div class="content flex-center column">
                <div class="title">给自己一个机会，成就梦想辉煌</div>
                <div class="freeReceive">免费领取试听名额</div>
                <div class="formData">
                    <div class="list Between">
                        <div class="userInfo">
                            <input type="text" placeholder="姓名" v-model="params.userName">
                        </div>
                        <div class="userInfo">
                            <input maxlength="11" type="text" v-model="params.phoneNumber" placeholder="手机号">
                        </div>
                    </div>
                    <div class="signUpBtn display pointer" @click="submitBtn">立即报名</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Banner from '@/components/banner'
import Lecturer from '@/components/lecturer'
import CourseTitle from './components/courseTitle.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
    components: {
        Banner,
        Swiper,
        SwiperSlide,
        CourseTitle,
        Lecturer
    },
    data() {
        return {
            params: {
                userName: "",
                phoneNumber: "",
                courseId: "",
                courseType: 1
            },
            recruitmentIndex: 0,
            servicesIndex: null,
            bannerList: [
                {
                    id: 1,
                    url: require("@/assets/img/home/barner1.png")
                },

            ],
            swiperList: [
                {},
                {},
                {},
                {},
            ],
            recruitmentList: [
                {
                    id: 1,
                    name: "前程无忧"
                },
                {
                    id: 2,
                    name: "智联招聘"
                },
                {
                    id: 3,
                    name: "58同城"
                },
                {
                    id: 4,
                    name: "职友集"
                },
            ],
            servicesList: [
                {
                    id: 1,
                    title: "成长培优",
                    cont: "记录学员从0到1的培训成长，手把手教学，终身免费技术升级指导",
                    url: require('@/assets/img/course/cz2.png'),
                    urls: require('@/assets/img/course/cz1.png')
                },
                {
                    id: 2,
                    title: "岗前培训",
                    cont: "以职场内训提升学员职场情商，情绪管理、心态调整等适应能力",
                    url: require('@/assets/img/course/px2.png'),
                    urls: require('@/assets/img/course/px1.png')
                },
                {
                    id: 3,
                    title: "指定名企项目实习",
                    cont: "公司每年都与国内外知名企业有合作项目，实习期内，可获得100-150元/天左右的实习报酬",
                    url: require('@/assets/img/course/xm2.png'),
                    urls: require('@/assets/img/course/xm1.png')
                },
                {
                    id: 4,
                    title: "推荐就业",
                    cont: "根据每位学员的专业水平进行推荐分配，以推荐到果尔佳公司下属施工单位为主，也可自谋就业",
                    url: require('@/assets/img/course/gwb2.png'),
                    urls: require('@/assets/img/course/gwb1.png')
                },
            ],
            swiperOption: {
                slidesPerView: 4,
                spaceBetween: 16,
                direction: 'horizontal',
                observer: true,
                observeParents: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                on: {
                    resize: () => {
                        this.$refs.swiper.$swiper.changeDirection(
                            window.innerWidth <= 400
                                ? 'vertical'
                                : 'horizontal'
                        )
                    }
                },
            },
            swiperOptions: {
                slidesPerView: 3,
                spaceBetween: 59,
                direction: 'horizontal',
                observer: true,
                observeParents: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                on: {
                    resize: () => {
                        this.$refs.swiper.$swiper.changeDirection(
                            window.innerWidth <= 400
                                ? 'vertical'
                                : 'horizontal'
                        )
                    }
                },
            },
            swiperOptionss: {
                slidesPerView: 4,
                spaceBetween: 26,
                direction: 'horizontal',
                observer: true,
                observeParents: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                on: {
                    resize: () => {
                        this.$refs.swiper.$swiper.changeDirection(
                            window.innerWidth <= 400
                                ? 'vertical'
                                : 'horizontal'
                        )
                    }
                },
            },
            formData: {}
        }
    },
    // 监听路由变化
    watch: {
        $route: "urlName",
    },
    mounted() {

        this.getData()
    },
    methods: {
        // 监听url参数变化
        urlName() {
            this.getData();
        },
        getData() {
            let typeWorkId = this.$route.query.id
            this.api.findCourseData({ typeWorkId: typeWorkId }).then(res => {
                if (res.code == 0) {
                    this.formData = res.data
                    this.params.courseId = res.data.id
                }
            })
        },
        changeBtn(index) {
            this, this.recruitmentIndex = index
        },
        mouseOver(index) {
            this.servicesIndex = index
        },
        mouseLeave() {
            this.servicesIndex = null
        },
        // 报名
        submitBtn() {
            if (this.params.userName == '') {
                return this.$message.error('请输入您的姓名')
            }
            if (this.params.courseId == '') {
                return this.$message.error('请选择课程')
            }
            if (!/^[0-9]{11}$/.test(this.params.phoneNumber)) {
                return this.$message.error('请正确输入手机号')
            }
            this.api.addCourseApply(this.params).then(res => {
                if (res.code == 0) {
                    this.$message.success('成功！')
                    this.params = {
                        userName: "",
                        phoneNumber: "",
                        courseId: "",
                    }
                }
            })
        },
        //联系客服
        service() {
            document.getElementById('nb-frame-chat-box').style.display = 'block'
        },

    }
}
</script>
<style lang="scss" scoped>
.course_box {
    .bannerImg {
        width: 100%;
    }

    .module1 {
        width: 100%;
        background: #fafafa;
        height: 547px;

        .swiper_box {
            margin-top: 39px;

            .list {
                .swiper_img {
                    width: 288px;
                    height: 200px;
                }

                .text {
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #333333;
                    line-height: 18px;
                    margin-top: 17px;
                }
            }

            .icon_left_right {
                width: 48px;
                height: 48px;
            }
        }
    }

    .module2 {
        height: 840px;

        .recruitment {
            margin-top: 40px;

            .recruitmentActive {
                background: #003C7E !important;
                color: white !important;
            }

            .list {
                width: 138px;
                height: 46px;
                background: #EEEEEE;
                border-radius: 3px;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                margin: 0 9px;
            }
        }

        .recruitment_img {
            margin-top: 45px;

            img {
                width: 717px;
                height: 460px;
            }
        }
    }

    .module3 {
        background: #fafafa;
        height: 838px;

        ::v-deep.el-carousel__container {
            width: 1200px;
            height: 555px;
            display: flex;
            align-items: center;
            justify-content: center;

        }

        .recommend_employment {
            width: 1200px;
            height: 508px;
            background: #FFFFFF;
            border-radius: 20px;
            margin-top: 27px;
            padding: 39px 29px;
            box-sizing: border-box;
            position: relative;

            .icon_tjImg {
                width: 130px;
                height: 130px;
                position: absolute;
                top: 0;
                right: 0;
            }

            .icon_tj {
                width: 680px;
                height: 430px;
            }

            .cont_box {
                margin-left: 40px;

                .jobs_tuitionFees {
                    margin-top: 28px;
                    margin-bottom: 40px;

                    .jobs {
                        font-size: 24px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #333333;
                        line-height: 24px;
                    }

                    .tuitionFee {
                        font-size: 24px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #FF881E;
                        line-height: 24px;
                        margin-left: 5px;
                    }
                }

                .list {
                    margin-bottom: 26px;

                    .label {
                        width: 123px;
                        height: 41px;
                        background: #FAFAFA;
                        border-radius: 5px;
                        margin-right: 20px;
                        font-size: 18px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #333333;
                    }

                    .text {
                        width: 270px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        line-height: 24px;
                        margin-top: 8px;
                    }

                    .course_introduction {
                        height: 137px;
                        line-height: 27px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 5;
                        -webkit-box-orient: vertical;
                    }
                }

                .btn {
                    width: 400px;
                    height: 44px;
                    background: #FF881E;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    margin-top: 80px;
                }
            }
        }
    }

    .module4 {
        height: 742px;

        .module4_content {
            width: 1400px;

            .carousel_box {
                margin-top: 51px;

            }

            ::v-deep.el-carousel__container {
                height: 378px;
                display: flex;
                align-items: center;
                justify-content: center;

            }

            ::v-deep.el-carousel {
                .el-carousel__item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .el-carousel__container {
                    .el-carousel__arrow {
                        width: 48px;
                        height: 48px;
                        font-size: 24px;
                        background: rgba(51, 51, 51, 0.5);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                }
            }

            .el_carousel_cont {
                width: 1200px;
                height: 378px;

                .el_carousel_cont_fl {
                    img {
                        width: 486px;
                        height: 377px;
                        margin-right: 78px;
                    }
                }

                .el_carousel_cont_fr {
                    padding-top: 36px;

                    .lecturer_name {
                        font-size: 28px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #003C7E;
                        line-height: 28px;
                        padding-bottom: 14px;
                        position: relative;

                        .line {
                            width: 44px;
                            height: 2px;
                            background: #FF881E;
                            position: absolute;
                            left: 0;
                            bottom: 0;
                        }
                    }

                    .lecturer_details {
                        width: 470px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #666666;
                        line-height: 30px;
                        margin-top: 18px;
                    }

                    .learn_more {
                        width: 167px;
                        height: 47px;
                        background: #FF881E;
                        border-radius: 5px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #FFFFFF;
                        margin-top: 40px;
                    }
                }
            }
        }
    }

    .module5 {
        height: 628px;
        background: #fafafa;

        .swiper_box {
            margin-top: 43px;

            .list {
                width: 360px;
                height: 334px;
                box-shadow: 0px 1px 5px 0px rgba(64, 64, 64, 0.25);

                .swiper_img {
                    width: 360px;
                    height: 260px;
                }

                .text {
                    width: 360px;
                    height: 70px;
                    background: white;
                }
            }

            .icon_left_right {
                width: 48px;
                height: 48px;
            }
        }
    }

    .module6 {
        height: 570px;

        .employment_services {
            margin-top: 30px;

            .list:nth-child(1) {
                margin-left: 0px !important;
            }

            .list:nth-child(2) {
                img {
                    width: 77px;
                    height: 73px;
                }
            }

            .list:nth-child(3) {
                img {
                    width: 67px;
                    height: 76px;
                }
            }

            .list:nth-child(4) {
                img {
                    width: 74px;
                    height: 73px;
                }
            }

            .listActive {
                background: #003C7E !important;
            }

            .list {
                width: 280px;
                height: 312px;
                background: #FFFFFF;
                box-shadow: 0px 1px 5px 0px rgba(64, 64, 64, 0.25);
                margin-left: 26px;

                img {
                    width: 75px;
                    height: 79px;
                    margin-top: 44px;
                }

                .active {
                    color: white !important;
                }

                .title {
                    font-size: 24px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #333333;
                    line-height: 24px;
                    margin-top: 42px;
                }

                .cont {
                    width: 225px;
                    height: 65px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;
                    line-height: 24px;
                    text-align: center;
                    margin-top: 16px;
                }
            }
        }
    }

    .module7 {
        height: 556px;
        background: #fafafa;

        .swiper_box {
            margin-top: 33px;

            ::v-deep.swiper-container {
                height: 350px;
            }

            .icon_left_right {
                width: 48px;
                height: 48px;
            }

            .list {
                width: 270px;
                height: 320px;
                background: #FFFFFF;
                box-shadow: 0px 1px 5px 0px rgba(64, 64, 64, 0.25);
                box-sizing: border-box;

                img {
                    width: 270px;
                    height: 260px;
                }

                .cont {
                    height: 80px;
                    padding: 17px;
                    box-sizing: border-box;

                    .name {
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #333333;
                        line-height: 16px;
                    }

                    .salary {
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        line-height: 14px;
                        margin-top: 13px;
                    }
                }
            }
        }
    }

    .module8 {
        min-width: 1200px;
        height: 518px;
        background: url("../../assets/img/course/bg.png") no-repeat;
        background-size: 100% 100%;

        .title {
            font-size: 36px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #F5F5F5;
            margin-top: 105px
        }

        .freeReceive {
            font-size: 30px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FCC800;
            margin-top: 22px
        }

        .formData {
            width: 671px;
            margin-top: 38px;

            .list {
                .userInfo {
                    width: 323px;
                    height: 54px;
                    background: #FFFFFF;

                    input {
                        width: 300px;
                        height: 54px;
                        padding-left: 22px;
                        font-size: 16px;
                    }
                }
            }

            .signUpBtn {
                width: 671px;
                height: 54px;
                background: #FCC800;
                font-size: 24px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                margin-top: 26px;
            }
        }
    }
}
</style>
<style lang="scss">
.swiper-button-prev:after,
.swiper-button-next:after {
    content: none;
}
</style>